:root {
  // New root colors
  --primary-regular: #175b5e;
  --primary-hover: #0a3f42;
  --primary-light: #4f9092;
  --primary-disabled: #cccfd2;
  --white: #fff;
  --black: #000;
  --black-100: #020d1c;
  --black-90: #061427;
  --grey: #676e77;
  --background-grey: #f5f5f5;
  --border-color: #cccfd2;
  --orange: #ed7916;
  --turquoise: #18b8a3;
  --blue: #183f73;
  --green: #769d2d;
  --orange-yellow: #c59a33;
  --red-orange: #bc401e;
  --cyan-blue: #2c70ba;
  --purple-magenta: #7b448e;
  //------------------------

  --brownish-orange: #cb7a2a;
  --greyish: #b9b9b9;
  --light-grey: #ededed;
  --medium-grey: #646462;
  --dark-slate-blue: #2c4173;
  --navy: #001623;
  //------
  --secondary-blue: #26538f;
  --secondary-orange: #ed7916;
  --secondary-green: #769d2d;
  --secondary-turquoise: #18b8a3;
  --secondary-purple: #6a16ed;
  --secondary-azure: #147ce4;
  --secondary-red: #ed3b16;
  --black-80: #343d49;
  --black-60: #676e77;
  --black-40: #9a9ea4;
  --black-20: #cccfd2;
  --black-05: #f2f3f3;
  --input-regular-border: #f3f4f5;
  --input-active-border: #183f73;
  --input-disabled-border: #f3f4f5;
}