@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
  box-sizing: border-box;
  outline: 0;
}

body {
  font-family: "Montserrat", "Helvetica", "Arial";
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  ::-webkit-scrollbar {
    width: 4px
  };
  ::-webkit-scrollbar-track {
    background: transparent;
  };
  ::-webkit-scrollbar-thumb {
    background: var(--primary-regular);
    border-radius: 4px;
  };
}
button {
  font-family: "Montserrat", "Helvetica", "Arial";
}

.text-block {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7142;
  p {
    margin-bottom: .5em;
  }
  ul {
    padding-left: 8px;
    margin-bottom: 8px;
    li {
      position: relative;
      padding-left: 12px;
      &:before {
        content:"";
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--black-90);
        position: absolute;
        left: 0;
        top: 10px
      }
    }
  }
  .MuiTypography-h6.MuiTypography-gutterBottom {
    margin-bottom: .35em;
  }
}

.projectTitle {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.hyperlink {
  color: #183f73;
  text-decoration: underline !important;
  :hover {
    color: #05234c !important;
  }
}

.wideButton {
  padding: 6px 50px !important;
  height: 48px !important;
  margin: 0px !important;
  margin: 0px 12px 12px 0px !important;
  max-width: 100% !important;
}

@media (max-width: 1500px) {
  .wideButton {
    width: 100% !important;
  }
}

a, a:hover {
  text-decoration: none;
}

.hidden {
  display: none !important;
  opacity: 0;
}

header.MuiAppBar-root {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 12px 16px rgba(0, 0, 0, 0.04);
  background-color: var(--white);
}

/* services description */
.description-service {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
}

.description-service p {
  padding: 5px 0;
  line-height: 1.64;
}

.description-service ul {
  padding: 5px 0;
  list-style-type: none;
}

.description-service ul li {
  padding: 10px 0;
}

.description-service ul li::before {
  font-family: "Font Awesome 5 Free";
  content: "\f058";
  font-weight: 900;
  font-size: 0.7rem;
  padding: 0 10px 0 0;
  color: #183f73;
}

.minus-m-25 {
  margin-left: -25px !important;
  margin-right: -25px !important;
}

.minus-mt-5 {
  margin-top: -5px !important;
}

.minus-mr-5 {
  margin-right: -5px !important;
}

/* bootstrap css partly */
/* bootstrap margin and padding */
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.pb-17{
  padding-bottom: 17px !important;
}

/* bootstrap text styling */
.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.flex-centered {
  flex-direction: row !important;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.fotterTextWithMargin {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 2;
  justify-content: flex-start;
  margin-bottom: 12px;
  color: #676e77;
  :hover {
    cursor: pointer;
  }
}

.fotterTextWithMargin, a {
  color: #676e77;
  :hover {
    cursor: pointer;
  }
}

.fotterTextWithoutMargin {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 2;
  justify-content: flex-start;
  color: #676e77;
  :hover {
    cursor: pointer;
  }
}

.heightVariant {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: auto;
}

.toolBarHeader {
  justify-content: flex-end;
  padding: 0;
  margin: 7px 24px;
}

.secondLayerGlobal {
  min-height: 100vh;
}

.markerTooltip {
  font-size: 14px !important;
  padding: 5px 20px !important;
}

.tooltipZIndex {
  z-index: 999 !important;
}

.leftBorderMap {
  border-left: 1px solid #c9d0d9 !important;
}

.generalMapWrapper {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
}

@-moz-document url-prefix() {
  @media (max-height: 600px) {
    .mozPad24Buttons {
      padding-bottom: 24px;
    }
  } 
}

.flag {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 11px;
  // transform: scale(1.2);
  background: url('../assets/flags.png') no-repeat;
}
.flag.flag-gu{background-position:-96px -55px}.flag.flag-MNG{background-position:-208px -88px}
.flag.flag-va{background-position:-48px -154px}.flag.flag-tibet{background-position:-32px -143px}
.flag.flag-fo{background-position:-64px -44px}.flag.flag-THA{background-position:-16px -143px}
.flag.flag-TUR{background-position:-144px -143px}.flag.flag-TLS{background-position:-80px -143px}
.flag.flag-KAZ{background-position:-144px -77px}.flag.flag-ZMB{background-position:-16px -165px}
.flag.flag-UZB{background-position:-32px -154px}.flag.flag-DNK{background-position:-64px -33px}
.flag.flag-scotland{background-position:-176px -121px}.flag.flag-gi{background-position:-224px -44px}
.flag.flag-GUY{background-position:-128px -55px}.flag.flag-BEN{background-position:-112px -11px}
.flag.flag-FRA{background-position:-80px -44px}.flag.flag-mo{background-position:-224px -88px}
.flag.flag-IRN{background-position:-112px -66px}.flag.flag-io{background-position:-80px -66px}
.flag.flag-TKM{background-position:-96px -143px}.flag.flag-CHE{background-position:-96px -22px}
.flag.flag-MLT{background-position:-32px -99px}.flag.flag-NLD{background-position:-240px -99px}
.flag.flag-gp{background-position:-16px -55px}.flag.flag-im{background-position:-48px -66px}
.flag.flag-TUV{background-position:-176px -143px}.flag.flag-MUS{background-position:-48px -99px}
.flag.flag-PER{background-position:-96px -110px}.flag.flag-vi{background-position:-112px -154px}
.flag.flag-HND{background-position:-176px -55px}.flag.flag-SSD{background-position:-128px -132px}
.flag.flag-ARE{background-position:-16px 0}.flag.flag-TCD{background-position:-240px -132px}
.flag.flag-PLW{background-position:0 -121px}.flag.flag-NIU{background-position:-32px -110px}
.flag.flag-BTN{background-position:-208px -11px}.flag.flag-ms{background-position:-16px -99px}
.flag.flag-CPV{background-position:-240px -22px}.flag.flag-ESP{background-position:-224px -33px}
.flag.flag-MHL{background-position:-144px -88px}.flag.flag-LAO{background-position:-160px -77px}
.flag.flag-VNM{background-position:-128px -154px}.flag.flag-PRY{background-position:-16px -121px}
.flag.flag-BRA{background-position:-176px -11px}.flag.flag-YEM{background-position:-224px -154px}
.flag.flag-IRL{background-position:0 -66px}.flag.flag-GHA{background-position:-208px -44px}
.flag.flag-COG{background-position:-80px -22px}.flag.flag-CUB{background-position:-224px -22px}
.flag.flag-HUN{background-position:-224px -55px}.flag.flag-SGP{background-position:-224px -121px}
.flag.flag-AUT{background-position:-176px 0}.flag.flag-LKA{background-position:-224px -77px}
.flag.flag-VUT{background-position:-144px -154px}.flag.flag-BOL{background-position:-160px -11px}
.flag.flag-JOR{background-position:-208px -66px}.flag.flag-ERI{background-position:-208px -33px}
.flag.flag-ZAF{background-position:-256px -154px}.flag.flag-SRB{background-position:-80px -121px}
.flag.flag-NRU{background-position:-16px -110px}.flag.flag-LSO{background-position:-256px -77px}
.flag.flag-JAM{background-position:-192px -66px}.flag.flag-TZA{background-position:-208px -143px}
.flag.flag-KIR{background-position:-16px -77px}.flag.flag-sj{background-position:0 -132px}
.flag.flag-CZE{background-position:-16px -33px}.flag.flag-PNG{background-position:-128px -110px}
.flag.flag-LVA{background-position:-32px -88px}.flag.flag-DOM{background-position:-96px -33px}
.flag.flag-LTU{background-position:-16px -88px}.flag.flag-NOR{background-position:-256px -99px}
.flag.flag-KWT{background-position:-112px -77px}.flag.flag-MEX{background-position:-96px -99px}
.flag.flag-yt{background-position:-240px -154px}.flag.flag-LBY{background-position:-48px -88px}
.flag.flag-CYP{background-position:0 -33px}.flag.flag-PHL{background-position:-144px -110px}
.flag.flag-MYS{background-position:-112px -99px}.flag.flag-SMR{background-position:-48px -132px}
.flag.flag-ETH{background-position:-240px -33px}.flag.flag-RUS{background-position:-96px -121px}
.flag.flag-TJK{background-position:-48px -143px}.flag.flag-ai{background-position:-64px 0}
.flag.flag-POL{background-position:-176px -110px}.flag.flag-PRK{background-position:-64px -77px}
.flag.flag-URY{background-position:-16px -154px}.flag.flag-GBR{background-position:-112px -44px}
.flag.flag-gs{background-position:-64px -55px}.flag.flag-kurdistan{background-position:-96px -77px}
.flag.flag-RWA{background-position:-112px -121px}.flag.flag-ECU{background-position:-128px -33px}
.flag.flag-MMR{background-position:-192px -88px}.flag.flag-PAN{background-position:-80px -110px}
.flag.flag-wales{background-position:-160px -154px}.flag.flag-KGZ{background-position:-256px -66px}
.flag.flag-VEN{background-position:-80px -154px}.flag.flag-TKL{background-position:-64px -143px}
.flag.flag-CAN{background-position:-16px -22px}.flag.flag-ISL{background-position:-128px -66px}
.flag.flag-KEN{background-position:-240px -66px}.flag.flag-ROU{background-position:-64px -121px}
.flag.flag-GNQ{background-position:-32px -55px}.flag.flag-PRT{background-position:-256px -110px}
.flag.flag-tf{background-position:-256px -132px}.flag.flag-AND{background-position:0 0}
.flag.flag-SVK{background-position:-16px -132px}.flag.flag-pm{background-position:-192px -110px}
.flag.flag-OMN{background-position:-64px -110px}.flag.flag-an{background-position:-112px 0}
.flag.flag-WSM{background-position:-192px -154px}.flag.flag-sh{background-position:-240px -121px}
.flag.flag-mp{background-position:-240px -88px}.flag.flag-GTM{background-position:-80px -55px}
.flag.flag-CAF{background-position:-64px -22px}.flag.flag-zanzibar{background-position:0 -165px}
.flag.flag-MWI{background-position:-80px -99px}.flag.flag-catalonia{background-position:-32px -22px}
.flag.flag-UGA{background-position:-240px -143px}.flag.flag-je{background-position:-176px -66px}
.flag.flag-COM{background-position:-32px -77px}.flag.flag-IND{background-position:-64px -66px}
.flag.flag-BFA{background-position:-48px -11px}.flag.flag-MCO{background-position:-80px -88px}
.flag.flag-SYR{background-position:-192px -132px}.flag.flag-SEN{background-position:-64px -132px}
.flag.flag-KOR{background-position:-80px -77px}.flag.flag-eu{background-position:-256px -33px}
.flag.flag-BRN{background-position:-144px -11px}.flag.flag-STP{background-position:-144px -132px}
.flag.flag-england{background-position:-192px -33px}.flag.flag-LCA{background-position:-192px -77px}
.flag.flag-DMA{background-position:-80px -33px}.flag.flag-BEL{background-position:-32px -11px}
.flag.flag-NIC{background-position:-224px -99px}.flag.flag-UKR{background-position:-224px -143px}
.flag.flag-MOZ{background-position:-128px -99px}.flag.flag-pf{background-position:-112px -110px}
.flag.flag-TUN{background-position:-112px -143px}.flag.flag-EST{background-position:-144px -33px}
.flag.flag-KSV{background-position:-208px -154px}.flag.flag-sx{background-position:-176px -132px}
.flag.flag-SDN{background-position:-192px -121px}.flag.flag-GRD{background-position:-128px -44px}
.flag.flag-CIV{background-position:-112px -22px}.flag.flag-SWZ{background-position:-208px -132px}
.flag.flag-CHL{background-position:-144px -22px}.flag.flag-FIN{background-position:0 -44px}
.flag.flag-GAB{background-position:-96px -44px}.flag.flag-JPN{background-position:-224px -66px}
.flag.flag-DEU{background-position:-32px -33px}.flag.flag-NPL{background-position:0 -110px}
.flag.flag-re{background-position:-48px -121px}.flag.flag-BGR{background-position:-64px -11px}
.flag.flag-SYC{background-position:-160px -121px}.flag.flag-NGA{background-position:-208px -99px}
.flag.flag-QAT{background-position:-32px -121px}.flag.flag-MKD{background-position:-160px -88px}
.flag.flag-aw{background-position:-208px 0}.flag.flag-KNA{background-position:-48px -77px}
.flag.flag-ALB{background-position:-80px 0}.flag.flag-BWA{background-position:-240px -11px}
.flag.flag-um{background-position:-256px -143px}.flag.flag-ky{background-position:-128px -77px}
.flag.flag-TTO{background-position:-160px -143px}.flag.flag-SOM{background-position:-80px -132px}
.flag.flag-LTU{background-position:0 -88px}.flag.flag-BLR{background-position:-256px -11px}
.flag.flag-BRB{background-position:0 -11px}.flag.flag-USA{background-position:0 -154px}
.flag.flag-MDA{background-position:-96px -88px}.flag.flag-ATG{background-position:-48px 0}
.flag.flag-hm{background-position:-160px -55px}.flag.flag-as{background-position:-160px 0}
.flag.flag-EGY{background-position:-160px -33px}.flag.flag-SLV{background-position:-160px -132px}
.flag.flag-SLE{background-position:-32px -132px}.flag.flag-fk{background-position:-32px -44px}
.flag.flag-ARM{background-position:-96px 0}.flag.flag-COK{background-position:-128px -22px}
.flag.flag-TWN{background-position:-192px -143px}.flag.flag-KHM{background-position:0 -77px}
.flag.flag-TON{background-position:-128px -143px}.flag.flag-SWE{background-position:-208px -121px}
.flag.flag-COD{background-position:-48px -22px}.flag.flag-pn{background-position:-208px -110px}
.flag.flag-GRC{background-position:-48px -55px}.flag.flag-IDN{background-position:-256px -55px}
.flag.flag-VCT{background-position:-64px -154px}.flag.flag-somaliland{background-position:-96px -132px}
.flag.flag-BDI{background-position:-96px -11px}.flag.flag-PAK{background-position:-160px -110px}
.flag.flag-pr{background-position:-224px -110px}.flag.flag-BGD{background-position:-16px -11px}
.flag.flag-COL{background-position:-192px -22px}.flag.flag-FSM{background-position:-48px -44px}
.flag.flag-bm{background-position:-128px -11px}.flag.flag-ARG{background-position:-144px 0}
.flag.flag-bv{background-position:-224px -11px}.flag.flag-SLB{background-position:-144px -121px}
.flag.flag-mq{background-position:-256px -88px}.flag.flag-eh{background-position:-176px -33px}
.flag.flag-BHR{background-position:-80px -11px}.flag.flag-ITA{background-position:-144px -66px}
.flag.flag-HRV{background-position:-192px -55px}.flag.flag-SAU{background-position:-128px -121px}
.flag.flag-MDV{background-position:-64px -99px}.flag.flag-MDG{background-position:-128px -88px}
.flag.flag-DZA{background-position:-112px -33px}.flag.flag-gg{background-position:-192px -44px}
.flag.flag-GMB{background-position:-256px -44px}.flag.flag-AFG{background-position:-32px 0}
.flag.flag-li{background-position:-208px -77px}.flag.flag-SUR{background-position:-112px -132px}
.flag.flag-vg{background-position:-96px -154px}.flag.flag-CRI{background-position:-208px -22px}
.flag.flag-tc{background-position:-224px -132px}.flag.flag-AGO{background-position:-128px 0}
.flag.flag-MAR{background-position:-64px -88px}.flag.flag-MRT{background-position:0 -99px}
.flag.flag-GIN{background-position:0 -55px}.flag.flag-NER{background-position:-176px -99px}
.flag.flag-nf{background-position:-192px -99px}.flag.flag-wf{background-position:-176px -154px}
.flag.flag-HKG{background-position:-144px -55px}.flag.flag-gf{background-position:-160px -44px}
.flag.flag-ps{background-position:-240px -110px}.flag.flag-ic{background-position:-240px -55px}
.flag.flag-cw{background-position:-256px -22px}.flag.flag-MLI{background-position:-176px -88px}
.flag.flag-ax{background-position:-224px 0}.flag.flag-gl{background-position:-240px -44px}
.flag.flag-DJI{background-position:-48px -33px}.flag.flag-CHN{background-position:-176px -22px}
.flag.flag-HTI{background-position:-208px -55px}.flag.flag-LBR{background-position:-240px -77px}
.flag.flag-TGO{background-position:0 -143px}.flag.flag-BIH{background-position:-256px 0}
.flag.flag-GEO{background-position:-144px -44px}.flag.flag-BLZ{background-position:0 -22px}
.flag.flag-AUS{background-position:-192px 0}.flag.flag-IRQ{background-position:-96px -66px}
.flag.flag-CMR{background-position:-160px -22px}.flag.flag-GNB{background-position:-112px -55px}
.flag.flag-AZE{background-position:-240px 0}.flag.flag-NAM{background-position:-144px -99px}
.flag.flag-FJI{background-position:-16px -44px}.flag.flag-ZWE{background-position:-32px -165px}
.flag.flag-BHS{background-position:-192px -11px}.flag.flag-ISR{background-position:-16px -66px}
.flag.flag-NZL{background-position:-48px -110px}.flag.flag-MNE{background-position:-112px -88px}
.flag.flag-SVN{background-position:-256px -121px}.flag.flag-nc{background-position:-160px -99px}
.flag.flag-LBN{background-position:-176px -77px}